$chatboss-blue: #00aeef;
$status-online: #4cd137;
$status-offline: #9b9898;
$status-waiting: #ffa600;
$status-danger: #ffcccc;
$red: #dd1b16;
$orange: #e46651;

// Box Shadows
$box-shadow-1: rgba(50, 50, 93, 0.11) 0px 4px 6px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
