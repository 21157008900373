@import 'vendors/variables';
@import 'mixins';
// Here you can add other styles
// Table Styles
.table-sm td {
  vertical-align: middle;
}
.table tbody tr:last-child {
  border-bottom: 1px solid $secondary;
}
// Form Inputs - File - shared/components/form-inputs/File.tsx
.file-input-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin: 0;
  padding: 0;
  .choose-button {
    @include fileInputButton($secondary);
    margin-right: 1rem;
  }
  .choosen-file {
    @include truncateText;
    display: inline-block;
    width: 200px;
    vertical-align: middle;
    color: darken($secondary, 20);
    font-size: 13px;
  }
  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.clear-button {
  @include fileInputButton(lighten($danger, 20));
  float: right;
}

@include media-breakpoint-between(xs, sm) {
  .clear-button {
    width: 100%;
  }
}

.colorpicker {
  position: absolute !important;
  z-index: 10;
  top: 80px;
}
