@mixin truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fileInputButton($color) {
  border: 1px solid $color;
  color: darken($color, 20);
  background-color: transparent;
  padding: 0 1rem;
  border-radius: 0.25rem;
}
