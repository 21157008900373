.login-container {
  background: #eaecee;
}
.login-card {
  border: none;
  border-radius: 0.75em;
  padding: 0;
  box-shadow: 0 0 0 1px rgba(89, 105, 129, 0.1), 0 3px 20px 0 rgba(89, 105, 129, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.logo-container {
  display: flex;
  justify-content: center;
  margin: 10rem 0 2rem 0;
}
.logo-container img {
  width: 65%;
}

.login-input {
  height: 40px;
  font-size: 15px;
}

.login-input-group-text {
  background: #f7f7f7;
}

.login-btn {
  font-weight: 400;
  font-size: 18px;
}
