.galleryItem {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center, center;
}

.image-gallery-thumbnail {
    width: auto;
    height: 80px;
    img {
        height: 72px;
    }
}

.image-gallery-slides,
.image-gallery-slide {
    height: calc(100vh - 90px);
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.image-gallery-download-button {
    padding: 20px
}

@media (max-width: 480px) {
    .image-gallery-icon.image-gallery-download-button {
        padding: 10px;
        &>img {
            height: 20px;
            width: 20px;
        }
    }
}